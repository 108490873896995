<template>
    <my-layout class="share_h5">
        <template #header>
            <div class="header" v-if="$route.query.phone">
                <img class="had_text" src="../../assets/img/h5/logo_title.png"/>
                <span class="entrance" @click="goHome">产品介绍</span>
            </div>
            <div class="header" v-else>
                <img class="logo" src="../../assets/img/h5/h5_minilogo.png"/>
                <span class="title_text">AI竞投-竞品PK</span>
                <span class="entrance" @click="goHome">进入官网</span>
            </div>
        </template>
        <template #body>
            <div class="company_info" v-if="$route.query.phone">
                <!--                <div class="head_img">-->
                <!--                    <div class="head_left">-->
                <!--                        <span class="title">专业的SEM竞品分析平台</span>-->
                <!--                        <span class="sub">AI竞投帮助您全面了解竞品、持续跟踪竞品</span>-->
                <!--                    </div>-->
                <!--                    <img class="head_right" src="../../assets/img/h5/head_img.png" alt="">-->
                <!--                </div>-->
                <!--                <div class="company_compete" >-->
                <!--                    <div class="company_name">{{company_a.company_name}}</div>-->
                <!--                    <div class="compete_cnt">-->
                <!--                        大数据监测全网共有-->
                <!--                        <span>{{company_a.nAdCompeteCnt}}</span>-->
                <!--                        家广告竞品公司-->
                <!--                    </div>-->
                <!--                    <div class="common_btn2" @click="showToast('请联系客户经理','查看全部竞品')">领取会员查看全部竞品</div>-->
                <!--                </div>-->
            </div>
            <div class="company_box">
                <div class="company_l">{{company_a.company_name}}</div>
                <img src="../../assets/img/h5/pk_icon.png"/>
                <div class="company_r">{{company_b.company_name}}</div>
            </div>
            <div class="info_box">
                <div class="title">推广概览</div>
                <div class="body_line">
                    <div class="left_company">
                        <div class="main_info">
                            <div class="company_name">{{company_a.company_name}}</div>
                            <span class="last_time">最后推广时间 {{company_a.nAdLatestTime}}</span>
                        </div>
                        <div class="percent_group">
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_a.nAdCnt)/Math.log(company_a.nAdCnt+company_b.nAdCnt)*48 +'px'}"></div>
                                <span class="percent_cnt">{{company_a.nAdCnt}}</span>
                            </div>
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_a.nAdWordCnt)/Math.log(company_a.nAdWordCnt+company_b.nAdWordCnt)*40 +'px'}"></div>
                                <span class="percent_cnt">{{company_a.nAdWordCnt}}</span>
                            </div>
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_a.nAdLinkCnt)/Math.log(company_a.nAdLinkCnt+company_b.nAdLinkCnt)*20 +'px'}">
                                </div><span class="percent_cnt">{{company_a.nAdLinkCnt}}</span>
                            </div>
                            <div class="percent_item" style="margin-bottom: 0">
                                <div v-if="company_a.nAdPlatform" class="percent_line" :style="{'width': company_a.nAdPlatform.length/7*8 +'px'}"></div>
                                <span v-if="company_a.nAdPlatform" class="percent_cnt">{{company_a.nAdPlatform.length}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="center">
                        <span>推广记录数</span>
                        <span>推广关键词</span>
                        <span>推广域名</span>
                        <span>推广平台</span>
                    </div>
                    <div class="right_company">
                        <div class="main_info">
                            <div class="company_name">{{company_b.company_name}}</div>
                            <span class="last_time">{{company_b.nAdLatestTime}} 最后推广时间</span>
                        </div>
                        <div class="percent_group">
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_b.nAdCnt)/Math.log(company_a.nAdCnt+company_b.nAdCnt)*48 +'px'}"></div>
                                <span class="percent_cnt">{{company_b.nAdCnt}}</span>
                            </div>
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_b.nAdWordCnt)/Math.log(company_a.nAdWordCnt+company_b.nAdWordCnt)*40 +'px'}"></div>
                                <span class="percent_cnt">{{company_b.nAdWordCnt}}</span>
                            </div>
                            <div class="percent_item">
                                <div class="percent_line" :style="{'width': Math.log(company_b.nAdLinkCnt)/Math.log(company_a.nAdLinkCnt+company_b.nAdLinkCnt)*20 +'px'}">
                                </div><span class="percent_cnt">{{company_b.nAdLinkCnt}}</span>
                            </div>
                            <div class="percent_item" style="margin-bottom: 0">
                                <div v-if="company_b.nAdPlatform" class="percent_line" :style="{'width': company_b.nAdPlatform.length/7*8 +'px'}"></div>
                                <span v-if="company_b.nAdPlatform" class="percent_cnt">{{company_b.nAdPlatform.length}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common_btn" @click="showToast('请联系客户经理','推广概览-查看详情')">查看详情</div>
            </div>
            <div class="info_box">
                <div class="title">关键词竞争</div>
                <span class="switch">
                    <span class="left" :class="pieCompany == 'company_b'?'selected':''" @click="changePie('company_b')">对比公司</span>
                    <span class="right" :class="pieCompany == 'company_a'?'selected':''" @click="changePie('company_a')">当前公司</span>
                </span>
                <div id="body_chart" ref="body_chart"></div>
                <div class="in_chart common_btn" @click="showToast('请联系客户经理下载关键词文件','下载关键词')">下载关键词</div>
            </div>
            <div class="share_box">
                <div class="company_compete">
                    <div class="company_name">{{company_a.company_name}}</div>
                    <div class="compete_cnt">
                        大数据监测全网共有
                        <span>{{company_a.nAdCompeteCnt}}</span>
                        家广告竞品公司
                    </div>
                    <div class="common_btn" @click="showToast('请联系客户经理','查看全部竞品')">查看全部竞品</div>
                </div>
                <div class="share_info" :style="$route.query.phone?{'padding-bottom': '0'}:''">
                    <div class="share_link">
                        <span class="label">分享</span>
                        <van-field v-model="share_link" id="link">
                            <template #button>
                                <div class="copy_btn" @click="copyText">复制</div>
                            </template>
                        </van-field>
                    </div>
                    <div class="common_btn2" @click="changeCompare">更换对比公司</div>
                    <div class="content" v-if="!$route.query.phone">
                        <div>客服电话：4006-173-173</div>
                        <div>客服邮箱：aicha@pingansec.com</div>
                        <div>专属客服微信：</div>
                        <img src="../../assets/img/h5/code.png" alt=""/>
                    </div>
                </div>
            </div>
            <div class="message_information" v-if="$route.query.phone">
                <div class="title">联系我们</div>
                <div class="company_name">
                    <img src="../../assets/img/h5/pingan_log.png" alt="">
                    <span>上海凭安征信服务有限公司</span>
                </div>
                <div class="text">aicha@pingansec.com</div>
                <div class="text">4006-173-173 （周一至周五 9:00-18:00）</div>
                <div class="code_group">
                    <div class="group_item">
                        <img src="../../assets/img/h5/official_accounts.png" alt="">
                        <span>关注公众号</span>
                    </div>
                    <div class="group_item right_code">
                        <img src="../../assets/img/h5/code2.png" alt=""/>
                        <span>专属客服</span>
                    </div>
                </div>
                <div class="footer_right">© 2021-2024 上海凭安网络科技有限公司 aijingtou.com  |  沪ICP备12039960号-38</div>
            </div>
            <div class="archival_information" v-if="!$route.query.phone">
                <div>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）All Rights Reserved</div>
                <div>沪ICP备12039960号-38</div>
            </div>
        </template>
        <div class="footer">
            <div class="to_compare" @click="getFree">免费会员</div>
        </div>
        <van-popup v-model="showVip" closeable class="vip_dialog">
            <div class="title">领取免费会员，即可使用</div>
            <div class="sub">【AI竞投】专业的SEM竞品分析平台</div>
            <van-field v-model="message_tel" type="tel" label="手机号" />
            <div class="clue_btn" @click="handleClue">确认领取</div>
        </van-popup>

        <van-popup v-model="showSuccess" closeable class="success_dialog">
            <div class="container">
                <div class="title">成功领取<span style="color: #f5a623;">3</span>天VIP</div>
                <div class="sub">已为你发送到账号：{{$C.hideTel(message_tel)}}</div>
                <div class="success_btn" @click="handleLogin">登录系统</div>
                <div class="tip">过期失效，请30分钟内立即前往登录，完成登录即可激活你的VIP。完整功能请激活VIP后移至电脑端登录使用。</div>
            </div>
        </van-popup>

        <van-popup v-model="has_got" closeable class="success_dialog">
            <div class="container">
                <div class="title"><img src="../../assets/img/h5/error_icon.png"/>您已领取过，请直接登录使用</div>
                <div class="sub">已为你发送到账号：{{$C.hideTel(message_tel)}}</div>
                <div class="success_btn" @click="handleLogin">登录系统</div>
                <div class="tip2">完整功能请移至电脑端登录实用。</div>
            </div>
        </van-popup>
    </my-layout>
</template>
<script>
  import * as api from '@/api/mobile'
  import * as echarts from "echarts";
  import {Field, Toast, Popup} from 'vant'
  import PinganAna from 'pingansec-vue-ana'
  export default {
    name: "share",
    components: {
      [Field.name]: Field,
      [Toast.name]: Toast,
      [Popup.name]: Popup,
    },
    data() {
      return {
        pieCompany: 'company_b',
        company_a: {},
        company_b: {},
        share_link: window.location.href,
        message_tel: '',  //判断页面场景是否为短信链接
        showVip: false,
        showSuccess: false,
        has_got: false,
      }
    },
    created() {
      if(this.$route.query.phone) {
        this.message_tel = this.$route.query.phone
      } else {
        PinganAna.fire(3022)
      }
      setTimeout(() => {
        if(document.getElementById('mobile_icon_div') || document.getElementById('mobile_ivt_div')) {
          document.getElementById('mobile_icon_div').style.display = 'none'
          document.getElementById('mobile_ivt_div').style.opacity = '0'
          document.getElementById('mobile_ivt_div').style.zIndex = '-1'
        }
      }, 500)
    },
    mounted() {
      this.getBaseData(this.$route.query.conpete_id).then(data => {
        let description = document.createElement('meta');
        let head =  document.head;
        description.name = 'Description';
        description.content = data.company_a.company_name + '-' + data.company_b.company_name + '的全网网络推广情况对比';
        head.insertBefore(description, head.children[4])
        if(this.$route.query.phone) {
          PinganAna.fire(2916);
          PinganAna.ready(() => {
            PinganAna.userClickLog(
              'h5页面访问',
              '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+data.company_b.company_name+'"}',
              '【AI竞投】短信运营',
              1,
              data.company_a.company_name,
              this.message_tel
            )
          })
        }
        this.$nextTick(() => {
          this.drawPie()
        })
      })
    },
    destroyed() {
      document.head.children[4].remove()
    },
    methods: {
      getFree() {
        PinganAna.ready(() => {
          PinganAna.userClickLog(
            '免费会员按钮-点击',
            '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+this.company_b.company_name+'"}',
            '【AI竞投】短信运营',
            1,
            this.company_a.company_name,
            this.message_tel
          )
        })
        this.showVip = true
      },
      handleLogin() {
        this.$router.push('/login_h5')
        PinganAna.ready(() => {
          PinganAna.userClickLog(
            '登录系统按钮-点击',
            '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+this.company_b.company_name+',"buttonName":"'+name+'"}',
            '【AI竞投】短信运营',
            1,
            this.company_a.company_name,
            this.message_tel
          )
        })
      },
      handleClue() {
        let telReg = (/^1[3456789]\d{9}$/)
        if(telReg.test(this.message_tel)) {
          let params = {
            query_type: 't1',
            account_phone: this.message_tel,
            logic_id: this.$route.query.logic_id,
            page_url: '/share_h5_a'
          }
          api.open_account(params).then(res => {
            if(res.data.result_code == 0) {
              this.showVip = false;
              this.showSuccess = true;
              PinganAna.ready(() => {
                PinganAna.userClickLog(
                  '确认领取成功',
                  '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+this.company_b.company_name+'"}',
                  '【AI竞投】短信运营',
                  1,
                  this.company_a.company_name,
                  this.message_tel
                )
              })
            } else {
              if(res.data.result_code == 120017) {
                this.showVip = false;
                this.has_got = true
              } else {
                Toast.fail(res.data.message);
              }
            }
          })
        } else {
          Toast.fail('请检查手机号格式！');
        }

      },
      goHome() {
        if(this.$route.query.phone) {
          PinganAna.fire(2927)
          PinganAna.ready(() => {
            PinganAna.userClickLog(
              '进入官网-点击',
              '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+this.company_b.company_name+'"}',
              '【AI竞投】短信运营',
              1,
              this.company_a.company_name,
              this.message_tel
            )
          })
        } else {
          PinganAna.fire(3018)
        }
        this.$router.push({
          path: '/h5',
          query: { fdhgjksdh: 'message'}
        })
      },
      changeCompare() {
        this.message_tel?this.showToast('','更换对比公司'):this.$router.push('/compare_h5')
      },
      showToast(msg,name) {
        if(this.$route.query.phone) {
          PinganAna.ready(() => {
            PinganAna.userClickLog(
              '对比h5-按钮-点击',
              '{"task":"'+this.$route.query.sms_sign+'","comparison":"'+this.company_b.company_name+',"buttonName":"'+name+'"}',
              '【AI竞投】短信运营',
              1,
              this.company_a.company_name,
              this.message_tel
            )
          })
          this.showVip = true
        } else {
          Toast({
            message: msg
          })
          switch (name) {
            case '推广概览-查看详情':
              PinganAna.fire(3023);
              break;
            case '下载关键词':
              PinganAna.fire(3024);
              break;
            case '查看全部竞品':
              PinganAna.fire(3025);
              break;
          }
        }
      },
      copyText() {
        if(!this.$route.query.phone) {
          PinganAna.fire(3026)
        }
        let text = document.getElementById('link');
        text.select();
        let flag = document.execCommand("Copy")
        if(flag) {
          Toast({
            message: '链接复制成功'
          })
        } else {
          Toast({
            message: '复制失败，请重试'
          })
        }
      },
      changePie(company) {
        this.pieCompany = company;
        echarts.dispose(this.$refs['body_chart'])
        this.$nextTick(() => {
          this.drawPie()
        })
      },
      drawPie() {
        let that = this;
        let myChart = echarts.init(document.getElementById('body_chart'))
        myChart.setOption({
          color: ["rgba(31, 129, 248, 0.7)", "rgba(1, 190, 215, 0.7)"],
          title: {
            text:that.pieCompany == 'company_a'?that.company_a.company_name:that.company_b.company_name,
            top: 60,
            left: 'center',
            textStyle: {
              fontWeight: 'normal',
              fontSize: 17,
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          series: [
            {
              name: '关键词竞争',
              type: 'pie',
              center: ['50%','55%'],
              radius: ['20%', '38%'],
              avoidLabelOverlap: false,
              labelLine: {
                show: true,
                minTurnAngle: 100,
              },
              label: {
                formatter: '{b|{b}} \n {per|{d}%} \n {c|{c}}个 \n {button|下载}',
                rich: {
                  b: {
                    color: '#999999',
                    fontSize: 12,
                    lineHeight: 20
                  },
                  per: {
                    color: '#999999',
                    fontSize: 12,
                    lineHeight: 20
                  },
                  c: {
                    color: '#333333',
                    fontSize: 12,
                    lineHeight: 20
                  },
                  button: {
                    color: '#1f81f8',
                    fontSize: 12,
                    lineHeight: 20
                  }
                }
              },
              data: [
                { value: that.pieCompany == 'company_a'?parseInt(that.company_a.sameWordCnt):parseInt(that.company_b.sameWordCnt), name: '相同词占比'},
                { value: that.pieCompany == 'company_a'?parseInt(that.company_a.diffWordCnt):parseInt(that.company_b.diffWordCnt), name: '独有词占比'}
              ]
            }
          ]
        })
        window.addEventListener("resize", () => { myChart.resize(); });
      },
      getBaseData(conpete_id) {
        let params = {
          conpete_id
        }
        let p = new Promise((resolve, reject) => {
          api.get_pk_info(params).then(res => {
            if(res.data.result_code == 0) {
              this.company_a = res.data.data.company_a
              this.company_b = res.data.data.company_b
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
    .share_h5 {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 43px 39px;
            font-size: 54px;
            color: #333333;
            font-weight: bold;
            background-color: #fff;
            border-bottom: 2px solid #f0f0f0;
            .title_text {
                position: relative;
                left: 40px;
            }
            .logo  {
                width: 98px;
                height: 83px;
            }
            .had_text {
                width: 281px;
                height: 84px;
            }
            .entrance {
                font-size: 42px;
                color: #1f81f8;
                font-weight: normal;
            }
        }
        .get_vip {
            width: 100%;
            height: 170px;
            font-size: 45px;
            color: #ffffff;
            display: flex;
            align-items: center;
            background: url("../../assets/img/h5/get_vip_bg.png");
            span {
                margin-left: 135px;
                margin-right: 116px;
            }
            .btn {
                display: inline-block;
                font-size: 42px;
                color: #1f81f8;
                font-weight: bold;
                background-color: #ffffff;
                padding: 40px 101px;
                border-radius: 80px;
            }
        }
        .layout_body {
            .common_btn {
                width: 600px;
                display: inline-block;
                background-color: #1f81f8;
                border: solid 2px #1f81f8;
                border-radius: 60px;
                color: #ffffff;
                font-size: 42px;
                padding: 40px 0;
                margin-bottom: 73px;
            }
            .common_btn2 {
                width: 600px;
                background-color: #fff;
                border: solid 2px #1f81f8;
                font-size: 42px;
                color: #1f81f8;
                display: inline-block;
                border-radius: 60px;
                padding: 40px 0;
                margin-bottom: 87px;
            }
            .company_info {
                text-align: center;
                background-color: #fff;
                .head_img {
                    width: 100%;
                    height: 360px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: url("../../assets/img/h5/head_bg.jpg") ;
                    .head_left {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        margin-left: 48px;
                        color: #ffffff;
                        .title {
                            font-size: 60px;
                            font-weight: bold;
                        }
                        .sub {
                            margin-top: 47px;
                            font-size: 39px;
                            opacity: 0.7;
                        }
                    }
                    .head_right {
                        width: 233px;
                        height: 250px;
                        margin-right: 24px;
                    }
                }
            }
            .company_compete {
                padding-top: 71px;
                border-bottom: 2px solid #f0f0f0;
                .company_name {
                    font-size: 48px;
                    font-weight: bold;
                }
                .compete_cnt{
                    font-size: 42px;
                    color: #999999;
                    margin-top: 51px;
                    margin-bottom: 63px;
                    span {
                        color: #1f81f8;
                    }
                }
            }
            .company_box {
                display: flex;
                position: relative;
                flex-direction: row;
                justify-content: space-between;
                padding: 40px;
                background-color: #fff;
                .company_l {
                    width: calc(50% - 100px);
                    font-size: 42px;
                    font-weight: bold;
                    color: #62a7fa;
                    line-height: 60px;
                    padding: 38px 54px 42px 24px;
                    background-color: rgba(98, 167, 250, 0.1);
                    border-radius: 9px;
                }
                .company_r {
                    width: calc(50% - 100px);
                    font-size: 42px;
                    font-weight: bold;
                    color: #06acc3;
                    line-height: 60px;
                    padding: 38px 22px 42px 52px;
                    background-color: rgba(77, 209, 227, 0.1);
                    border-radius: 9px;
                }
                img {
                    position: absolute;
                    left: calc(50% - 50px);
                    top: calc(50% - 50px);
                    width: 100px;
                    height: 100px;
                }
            }
            .info_box {
                position: relative;
                text-align: center;
                background-color: #fff;
                margin-top: 30px;
                .in_chart {
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 300px);
                }
                .title {
                    font-size: 48px;
                    font-weight: bold;
                    color: #333333;
                    padding: 51px;
                    text-align: center;
                    border-bottom: 2px solid #f0f0f0;
                }
                .body_line {
                    display: inline-flex;
                    align-items: flex-end;
                    padding: 68px 42px;
                    .main_info {
                        display: flex;
                        flex-direction: column;
                        .company_name {
                            font-size: 42px;
                            color: #333333;
                            cursor: pointer;
                            max-width: 375px;
                            line-height: 50px;
                        }
                        .last_time {
                            margin-top: 34px;
                            margin-bottom: 78px;
                            font-size: 30px;
                            color: #999999;
                            white-space: nowrap;
                        }
                    }
                    .percent_group {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .percent_item {
                            display: inline-flex;
                            align-items: center;
                            margin-bottom: 56px;
                            .percent_line {
                                display: inline-block;
                                height: 15px;
                                border-radius: 9px;
                                animation-name: example;
                                animation-duration: 1s;
                            }
                            @keyframes example {
                                from {width: 0;}
                            }
                            .percent_cnt {
                                font-size: 30px;
                                color: #999999;
                                max-width: 148px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .left_company {
                        .main_info {
                            align-items: flex-end;
                            text-align: right;
                        }
                        .percent_group {
                            align-items: flex-end;
                            .percent_item {
                                flex-direction: row-reverse;
                                .percent_line {
                                    background-color: rgba(31, 129, 248, 0.7);
                                }
                                .percent_cnt {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                    .center {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        width: 237px;
                        height: 300px;
                        font-size: 36px;
                        color: #999999;
                    }
                    .right_company {
                        .main_info {
                            text-align: left;
                            align-items: flex-start;
                        }
                        .percent_group {
                            align-items: flex-start;
                            .percent_item {
                                flex-direction: row;
                                .percent_line {
                                    background-color: rgba(1, 190, 215, 0.7);
                                }
                                .percent_cnt {
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }
                #body_chart {
                    width: 100%;
                    height: 1300px;
                }
                .switch {
                    font-size: 36px;
                    color: #333333;
                    cursor: pointer;
                    position: absolute;
                    right: 41px;
                    top: 204px;
                    z-index: 10;
                    .left {
                        padding: 22px 38px;
                        border-top-left-radius: 9px;
                        border-bottom-left-radius: 9px;
                        border: solid 2px #ebeff2;
                    }
                    .right {
                        padding: 22px 38px;
                        border-top-right-radius: 9px;
                        border-bottom-right-radius: 9px;
                        border: solid 2px #ebeff2;
                        /*border-left: none;*/
                    }
                    .selected {
                        border-color: #1f81f8;
                        color: #1f81f8;
                    }
                }
            }
            .share_box {
                position: relative;
                text-align: center;
                background-color: #fff;
                margin-top: 30px;
                .share_info {
                    padding: 62px 40px;
                    .share_link {
                        display: flex;
                        align-items: center;
                        .label {
                            text-align: left;
                            width: 13%;
                            font-size: 42px;
                            color: #333333;
                        }
                    }
                }
                .content {
                    background-color: #fbfbfb;
                    border-radius: 12px;
                    padding: 63px 44px 50px 44px;
                    color: #666666;
                    font-size: 42px;
                    line-height: 80px;
                    text-align: left;
                    img {
                        margin-top: 17px;
                        width: 319px;
                        height: 319px;
                    }
                }
            }
            .message_information {
                padding: 84px 58px 77px 58px;
                background-color: #0b1531;
                font-size: 36px;
                color: #ffffff;
                margin-bottom: 150px;
                .company_name {
                    margin-top: 43px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 48px;
                        height: 45px;
                        margin-right: 10px;
                    }
                }
                .text {
                    margin-top: 30px;
                    opacity: 0.7;
                }
                .title {
                    font-size: 42px;
                }
                .footer_right {
                    text-align: center;
                    opacity: 0.7;
                    margin-top: 70px;
                }
                .code_group {
                    margin-top: 46px;
                    .group_item {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        img {
                            width: 205px;
                            height: 205px;
                            margin-bottom: 27px;
                        }
                    }
                    .right_code {
                        margin-left: 97px;
                    }
                }
            }
            .archival_information {
                width: 100%;
                text-align: center;
                line-height: 60px;
                font-size: 36px;
                color: #999999;
                margin-bottom: 230px;
                margin-top: 68px;
            }
        }
        .footer {
            position: fixed;
            bottom: 0;
            z-index: 20;
            width: calc(100% - 80px);
            background-color: #fff;
            padding: 30px 40px;
            .to_compare {
                font-size: 48px;
                background-color: #1f81f8;
                border-radius: 12px;
                color: #ffffff;
                padding: 42px 0;
                text-align: center;
            }
        }
        .footer2 {
            position: fixed;
            bottom: 0;
            z-index: 20;
        }
        .vip_dialog {
            width: 80%;
            padding: 40px;
            border-radius: 21px;
            .title {
                margin-top: 42px;
                font-size: 54px;
                color: #1f81f8;
                text-align: center;
            }
            .sub {
                font-size: 36px;
                color: #999999;
                text-align: center;
                margin-top: 33px;
                margin-bottom: 40px;
            }
            .clue_btn {
                width: calc(100% - 80);
                background-color: #1f81f8;
                border-radius: 12px;
                color: #ffffff;
                font-size: 48px;
                padding: 42px;
                text-align: center;
                margin-top: 52px;
            }
        }
        .success_dialog {
            width: 90%;
            border-radius: 21px;
            overflow-y: unset;
            .container {
                padding: 40px;
                background: url("../../assets/img/h5/vip_bg.png")no-repeat;
                background-size: 273*1.2px 287*1.2px;
                .title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 54px;
                    color: #333333;
                    text-align: center;
                    margin-top: 42px;
                    img {
                        width: 52px;
                        height: 52px;
                        opacity: 0.7;
                        margin-right: 17px;
                    }
                }
                .sub {
                    font-size: 42px;
                    color: #f5a623;
                    text-align: center;
                    margin-top: 58px;
                }
                .success_btn {
                    width: calc(100% - 80);
                    background-color: #f5a623;
                    border-radius: 12px;
                    color: #ffffff;
                    font-size: 48px;
                    padding: 42px;
                    text-align: center;
                    margin-top: 60px;
                }
                .tip {
                    position: absolute;
                    bottom: -200px;
                    left: 20px;
                    color: #ffffff;
                    font-size: 36px;
                    line-height: 60px;
                }
                .tip2 {
                    position: absolute;
                    bottom: -87px;
                    left: 20px;
                    color: #ffffff;
                    font-size: 36px;
                    line-height: 60px;
                }
            }
        }

    }
</style>

<style lang="scss">
    .share_link {
        margin-bottom: 72px;
        .van-cell {
            padding: 10px;
            background-color: #f5f6f7;
            border-radius: 80px;
            .van-field__left-icon {
                display: inline-flex;
                align-items: center;
                margin-right: 20px;
            }
            .van-field__control {
                padding: 31px 35px 24px 49px;
                color: #999999;
                font-size: 42px;
                text-overflow: ellipsis;
            }
            .van-field__button {
                font-size: 42px;
                color: #4c9af9;
                padding: 30px 59px 31px 33px;
                border-left: 1px solid #dcdee1;
            }
            .input_img {
                width: 50px;
                height: 50px;
            }
        }
    }
    .van-popup {
        .van-cell__title {
            font-size: 42px;
            color: #333333;
        }
        .van-cell__value {
            font-size: 42px;
            color: #333333;
            opacity: 0.45;
        }
        .van-popup__close-icon {
            font-size: 27px;
        }
    }
</style>
